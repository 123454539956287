import type { ActionFunctionArgs } from '@remix-run/node';
import { redirect } from '@remix-run/node';
import { Form, useActionData, useNavigation, useSearchParams } from '@remix-run/react';
import { Button } from '@venncity/venn-ds';
import { get } from 'lodash-es';
import { Input, Label, Text, TextField } from 'react-aria-components';
import invariant from 'tiny-invariant';
import { z } from 'zod';
import { answerAuthChallenge, initiateAuthChallenge } from '~/lib/auth.server';
import { getSession, initUserSession, sessionHeaders } from '~/lib/session.server';
import { createAnonymousVennGraphClient } from '~/lib/venn-graph-client.server';
import { handleExceptions, notFound } from '~/utils/http.server';

export async function action({ request }: ActionFunctionArgs) {
  const session = await getSession(request);

  const url = new URL(request.url);
  const impersonationToken = url.searchParams.get('impersonation');
  const isImpersonating = Boolean(impersonationToken);
  const authChallengeMethod = isImpersonating ? 'IMPERSONATION' : 'EMAIL';

  const formData = await request.formData();

  const formDataValue = formData.get('email');
  const email = typeof formDataValue === 'string' ? formDataValue.toLowerCase() : '';
  const timezone = formData.get('timezone');

  invariant(timezone, 'make sure you pass the user timezone via a hidden input');

  session.set('user-timezone', timezone.toString());

  try {
    const EmailLoginSchema = z.object({
      email: z.string().email({ message: 'Invalid email address' }).min(1),
    });

    const validated = await EmailLoginSchema.parseAsync({ email });

    if (process.env.ENVIRONMENT === 'playwright') {
      const client = createAnonymousVennGraphClient();

      const { authChallengeCreate } = await client.mutation({
        authChallengeCreate: {
          __args: {
            data: {
              username: validated.email,
            },
          },
          authSession: true,
          type: true,
        },
      });

      session.set('login-session', authChallengeCreate.authSession);
      session.set('login-method', authChallengeCreate.type);
    } else {
      const cognitoUser = await initiateAuthChallenge({
        username: validated.email,
        method: authChallengeMethod,
      });

      const loginSession = get(cognitoUser, 'Session', '');

      session.set('login-session', loginSession);

      if (impersonationToken) {
        const cognitoUserSession = await answerAuthChallenge({
          code: impersonationToken,
          username: validated.email,
          session: loginSession,
        });

        const accessToken = cognitoUserSession.getIdToken().getJwtToken();
        const refreshToken = cognitoUserSession.getRefreshToken().getToken();

        session.set('login-method', authChallengeMethod);

        return await initUserSession({ request, session, accessToken, refreshToken });
      }
    }

    session.set('login-username', validated.email);
    session.set('login-method', authChallengeMethod);

    return redirect(`/auth/verify`, {
      headers: await sessionHeaders(session),
    });
  } catch (error) {
    if ((error as { code: string }).code === 'UserNotFoundException') {
      return notFound(
        {
          errors: {
            email: ["User can't be found"],
          },
          success: false,
        },
        {
          headers: await sessionHeaders(session),
        },
      );
    } else {
      return await handleExceptions({
        error,
        request,
        flash: {
          title: 'Login failed, please try again',
          description: 'If the problem persists, please contact support.',
        },
      });
    }
  }
}

export default function LoginEmail() {
  const navigation = useNavigation();
  const actionData = useActionData<typeof action>();
  const [searchParams] = useSearchParams();

  const errorMessage = actionData?.errors?.email?.[0] || get(actionData, 'message');

  const cta = searchParams.get('impersonation') ? 'Impersonate' : 'Continue';

  const isSubmitting =
    navigation.formMethod === 'POST' &&
    (navigation.state === 'loading' || navigation.state === 'submitting');

  return (
    <Form className="mt-6 flex flex-col" method="POST" noValidate>
      <input
        name="timezone"
        type="hidden"
        value={Intl.DateTimeFormat().resolvedOptions().timeZone}
      />
      <TextField className="flex flex-col" isInvalid={!!errorMessage} name="email" type="email">
        <Label className="font-medium text-white">Login with your email</Label>
        <Input
          className="placeholder:text-grey-600 text-grey-900 mt-2 flex-1 rounded-lg bg-white p-4 font-medium focus:outline-none"
          placeholder="name@example.com"
        />
        {errorMessage && (
          <Text className="text-primary-100 mt-2 text-xs" slot="errorMessage">
            {errorMessage}
          </Text>
        )}
      </TextField>
      <Button
        className="mt-6"
        disabled={isSubmitting}
        htmlType="submit"
        loading={isSubmitting}
        shape="default"
        size="large"
        type="primary">
        {cta}
      </Button>
    </Form>
  );
}
